import React from "react";
import {Button, Form, Input, Typography} from "antd";
import {auth} from "../firebase/firebase";
import {Link} from "react-router-dom";
import logo from "../assets/images/logo_white.png";

export default class extends React.Component {
	handleSubmit = (e) => {
		if (this.state.email) {
			auth.sendPasswordResetEmail(this.state.email)
				.then(err => {
					if (!err) {
						this.props.history.push("/iniciar");
					}
				})
		}
		e.preventDefault();
	};

	render() {
		return (
			<div className="gx-app-login-wrap">
				<div className="gx-app-login-container">
					<div className="gx-app-login-main-content">
						<div className="gx-app-login-content gx-m-5">
							<Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
								<Link to={"/iniciar"}>
									<img className={"gx-mb-3"} alt="Logo" src={logo} width={"100%"}/>
								</Link>
								<Form.Item>
									<Input placeholder="Correo Electrónico" onChange={e => this.setState({email: e.target.value})}/>
								</Form.Item>
								<Form.Item>
									<Button type="primary" className="gx-mb-0" htmlType="submit">
										Reiniciar Contraseña
									</Button>
								</Form.Item>
							</Form>
						</div>
					</div>
				</div>
				<div style={{
					position: "absolute",
					textAlign: "center",
					width: "100%",
					bottom: 30,
				}}>
					<Typography.Text style={{color: "#C1BB70", fontSize: "16px", textTransform: "uppercase"}}>
						Todos los derechos reservados.
						Desarrollado por {" "}
						<a href={"https://studiomediaagency.com"} target={"_blank"} style={{color: "#FFF"}} without rel="noopener noreferrer">
							StudioMediaAgency
						</a>
					</Typography.Text>
				</div>
			</div>
		);
	}
}
