import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";

class UserProfile extends Component {
	render() {
		const userMenuOptions = (
			<ul className="gx-user-popover">
				{/*<li>My Account</li>*/}
				{/*<li>Connections</li>*/}
				<li onClick={() => this.props.userSignOut()}>Logout
				</li>
			</ul>
		);

		return (
			<div className="gx-flex-row gx-align-items-center gx-mb-2 gx-avatar-row">
				<Popover placement="bottomRight" content={userMenuOptions} trigger="click">
					<Avatar icon="user" src={this.props.auth.photoURL && this.props.auth.photoURL !== "null" ? this.props.auth.photoURL : null} className="gx-size-40 gx-pointer gx-mr-3" alt={this.props.auth.displayName}/>
					<span className="gx-avatar-name">
						{this.props.auth.displayName && this.props.auth.displayName !== "null" ? this.props.auth.displayName : this.props.auth.email.split("@")[0]}
						<i className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/>
					</span>
				</Popover>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		auth: state.auth,
	}
};

export default connect(mapStateToProps, {userSignOut})(UserProfile);
