import React from "react";
import {Typography, Button, Form, Input, message} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {hideMessage, showAuthLoader, userSignIn,} from "../appRedux/actions/Auth";
import CircularProgress from "../components/CircularProgress";
import logo from "../assets/images/logo_white.png";

class SignIn extends React.Component {

	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields((err, values) => {
			if (!err) {
				this.props.showAuthLoader();
				this.props.userSignIn(values);
			}
		});
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props.showMessage) {
			setTimeout(() => {
				this.props.hideMessage();
			}, 100);
		}
		if (this.props.authUser !== null) {
			this.props.history.push("/");
		}
	}

	render() {
		const {getFieldDecorator} = this.props.form;
		const {showMessage, loader, alertMessage} = this.props;

		return (
			<div className="gx-app-login-wrap">
				<div className="gx-app-login-container">
					<div className="gx-app-login-main-content">
						<div className="gx-app-login-content gx-m-5">
							<Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
								<Link to={"/iniciar"}>
									<img className={"gx-mb-3"} alt="Logo" src={logo} width={"100%"}/>
								</Link>
								<Form.Item>
									{getFieldDecorator("email", {
										rules: [{
											required: true, type: "email", message: "El correo no parece valido",
										}],
									})(
										<Input placeholder="Correo Electrónico"/>
									)}
								</Form.Item>
								<Form.Item>
									{getFieldDecorator("password", {
										rules: [{required: true, message: "Es necesario una contraseña"}],
									})(
										<Input type="password" placeholder="Contraseña"/>
									)}
								</Form.Item>
								<Form.Item>
									<Button type="primary" className="gx-mb-0" htmlType="submit">
										Iniciar Sesión
									</Button>
									<Link to={"/reiniciar"}>
										<Button type="link" className="gx-mb-0" style={{color: "#FFF",}}>
											<u>Reiniciar Contraseña</u>
										</Button>
									</Link>
								</Form.Item>
							</Form>
						</div>

						{loader ?
							<div className="gx-loader-view">
								<CircularProgress/>
							</div> : null}
						{showMessage ?
							message.error(alertMessage.toString()) : null}
					</div>
				</div>
				<div style={{
					position: "absolute",
					textAlign: "center",
					width: "100%",
					bottom: 30,
				}}>
					<Typography.Text style={{color: "#C1BB70", fontSize: "16px", textTransform: "uppercase"}}>
						Todos los derechos reservados.
						Desarrollado por {" "}
						<a href={"https://studiomediaagency.com"} target={"_blank"} style={{color: "#FFF"}} without rel="noopener noreferrer">
							StudioMediaAgency
						</a>
					</Typography.Text>
				</div>
			</div>
		);
	}
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
	const {loader, alertMessage, showMessage, authUser} = auth;
	return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
	userSignIn,
	hideMessage,
	showAuthLoader,
})(WrappedNormalLoginForm);
