import firebase from "firebase";

// Initialize Firebase
const config = {
  apiKey: "AIzaSyBLAw_0chZo20hCWO9z2eS81SDvahWFsy4",
  authDomain: "bufalo-grain.firebaseapp.com",
  databaseURL: "https://bufalo-grain.firebaseio.com",
  projectId: "bufalo-grain",
  storageBucket: "bufalo-grain.appspot.com",
  messagingSenderId: "470066299258"
};

firebase.initializeApp(config);
const auth = firebase.auth();
const firestore = firebase.firestore();
const FieldValue = firebase.firestore.FieldValue;

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

if (window.location.hostname === "localhost" && process.env.REACT_APP_DEV === "true") {
	firestore.settings({
		host: "localhost:8080",
		ssl: false,
	});
}

export {
  auth,
  firestore,
  FieldValue,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider
};
