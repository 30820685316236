import React, {Component} from "react";
import {connect} from "react-redux";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
// import AppsNavigation from "./AppsNavigation";
import {
	NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
	NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
	THEME_TYPE_LITE
} from "../../constants/ThemeSetting";

class SidebarContent extends Component {

	getNoHeaderClass = (navStyle) => {
		if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
			return "gx-no-header-notifications";
		}
		return "";
	};
	getNavStyleSubMenuClass = (navStyle) => {
		if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
			return "gx-no-header-submenu-popup";
		}
		return "";
	};

	render() {
		const {themeType, navStyle, pathname} = this.props;
		const selectedKeys = pathname.substr(1);
		const defaultOpenKeys = selectedKeys.split("/")[1];
		return (<Auxiliary>

				<SidebarLogo/>
				<div className="gx-sidebar-content gx-mt-4">
					<div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
						<UserProfile/>
						{/*<AppsNavigation/>*/}

					</div>
					<CustomScrollbars className="gx-layout-sider-scrollbar">
						<Menu
							defaultOpenKeys={[defaultOpenKeys]}
							selectedKeys={[selectedKeys]}
							theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
							mode="inline">

							<Menu.Item key="inicio">
								<Link to="/inicio">
									<i style={{width: 36, fontSize: 18}} className="fas fa-home"/>
									<span>Inicio</span>
								</Link>
							</Menu.Item>

							<Menu.ItemGroup title={<span className="submenu-title-wrapper">Captura</span>}>
								<Menu.Item key="almacenes">
									<Link to="/almacenes">
										<i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-warehouse"/>
										<span>Almacenes</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="clientes">
									<Link to="/clientes">
										<i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-user-tie"/>
										<span>Clientes</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="proveedores">
									<Link to="/proveedores">
										<i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-industry-alt"/>
										<span>Proveedores</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="transportistas">
									<Link to="/transportistas">
										<i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-truck-moving"/>
										<span>Transportistas</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="productos">
									<Link to="/productos">
										<i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-wheat"/>
										<span>Productos</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="calidades">
									<Link to="/calidades">
										<i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-tint"/>
										<span>Calidades</span>
									</Link>
								</Menu.Item>
							</Menu.ItemGroup>

							<Menu.ItemGroup title={<span className="submenu-title-wrapper">Movimientos</span>}>
								<Menu.SubMenu key={"contratos"} title={<span><i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-file-invoice-dollar"/>Contratos</span>}>
									<Menu.Item key="contratos/compra">
										<Link to="/contratos/compra">
											<span>Contratos de Compra</span>
										</Link>
									</Menu.Item>
									<Menu.Item key="contratos/venta">
										<Link to="/contratos/venta">
											<span>Contratos de Venta</span>
										</Link>
									</Menu.Item>
								</Menu.SubMenu>
								<Menu.SubMenu key={"boletas"} title={<span><i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-clipboard-check"/>Boletas</span>}>
									<Menu.Item key="boletas/entrada">
										<Link to="/boletas/entrada">
											<span>Boletas de Entrada</span>
										</Link>
									</Menu.Item>
									<Menu.Item key="boletas/salida">
										<Link to="/boletas/salida">
											<span>Boletas de Salida</span>
										</Link>
									</Menu.Item>
								</Menu.SubMenu>
							</Menu.ItemGroup>
							<Menu.ItemGroup title={<span className="submenu-title-wrapper">Administración</span>}>
								<Menu.SubMenu key={"reportes"} title={<span><i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-chart-bar"/>Reportes</span>}>
									<Menu.Item key="reportes/cliente">
										<Link to="/reportes/cliente">
											<span>Cliente</span>
										</Link>
									</Menu.Item>
									<Menu.Item key="reportes/contrato">
										<Link to="/reportes/contrato">
											<span>Contrato</span>
										</Link>
									</Menu.Item>
									<Menu.Item key="reportes/saldos">
										<Link to="/reportes/saldos">
											<span>Resumen Saldos por Cliente</span>
										</Link>
									</Menu.Item>
									<Menu.Item key="reportes/reporte-saldos">
										<Link to="/reportes/reporte-saldos">
											<span>Reporte de Saldos</span>
										</Link>
									</Menu.Item>
								</Menu.SubMenu>
								<Menu.Item key="usuarios">
									<Link to="/usuarios">
										<i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-users"/>
										<span>Usuarios</span>
									</Link>
								</Menu.Item>
								<Menu.Item key="configuracion">
									<Link to="/configuracion">
										<i style={{width: 36, marginTop: 10, fontSize: 18}} className="fas fa-cog"/>
										<span>Configuración</span>
									</Link>
								</Menu.Item>
							</Menu.ItemGroup>
						</Menu>
					</CustomScrollbars>
				</div>
			</Auxiliary>
		);
	}
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings}) => {
	const {navStyle, themeType, locale, pathname} = settings;
	return {navStyle, themeType, locale, pathname}
};
export default connect(mapStateToProps)(SidebarContent);

