import React from "react";
import {Route, Switch} from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}inicio`} component={asyncComponent(() => import("./home/"))}/>

      <Route path={`${match.url}almacenes/`} component={asyncComponent(() => import("./almacenes/"))}/>
      <Route path={`${match.url}clientes/`} component={asyncComponent(() => import("./clientes/"))}/>
      <Route path={`${match.url}proveedores/`} component={asyncComponent(() => import("./proveedores/"))}/>
      <Route path={`${match.url}transportistas/`} component={asyncComponent(() => import("./transportistas/"))}/>
      <Route path={`${match.url}productos/`} component={asyncComponent(() => import("./productos/"))}/>
      <Route path={`${match.url}calidades/`} component={asyncComponent(() => import("./calidades/"))}/>
      <Route path={`${match.url}contratos/compra`} component={asyncComponent(() => import("./contratos/compra"))}/>
      <Route path={`${match.url}contratos/venta`} component={asyncComponent(() => import("./contratos/venta"))}/>
      <Route path={`${match.url}boletas/entrada`} component={asyncComponent(() => import("./boletas/entrada"))}/>
      <Route path={`${match.url}boletas/salida`} component={asyncComponent(() => import("./boletas/salida"))}/>
      <Route path={`${match.url}reportes/cliente`} component={asyncComponent(() => import("./reportes/cliente"))}/>
      <Route path={`${match.url}reportes/contrato`} component={asyncComponent(() => import("./reportes/contrato"))}/>
      <Route path={`${match.url}reportes/saldos`} component={asyncComponent(() => import("./reportes/saldos"))}/>
      <Route path={`${match.url}reportes/reporte-saldos`} component={asyncComponent(() => import("./reportes/reporte-saldos"))}/>
      <Route path={`${match.url}usuarios`} component={asyncComponent(() => import("./usuarios"))}/>
      <Route path={`${match.url}configuracion`} component={asyncComponent(() => import("./configuracion"))}/>
    </Switch>
  </div>
);

export default App;
